.flags-dropdown .dropdown-menu {
    width: auto;
    z-index: 9999999;
    margin-top: 12px;
}

.flags-dropdown .nav-link.dropdown-toggle {
    padding: 0;
}

.flags-dropdown .nav-link.dropdown-toggle::after {
    display: none;
}

.flags-dropdown .nav-link.dropdown-toggle img {
    display: inline-block;
    width: 22px;
    height: 22px;
    -o-object-fit: fill;
    object-fit: fill;
    -o-object-position: center;
    object-position: center;
    border: 1px solid #ccc;
    border-radius: 50%;
}

.flags-dropdown .dropdown-menu .dropdown-item {
    padding: 10px;
}

.flags-dropdown .dropdown-menu .dropdown-item:active {
    background-color: #828282;
}

.flags-dropdown .dropdown-menu .dropdown-item .inner-text {
    display: flex;
}

.flags-dropdown .dropdown-menu .dropdown-item .inner-text img {
    display: block;
    width: 25px;
    height: 25px;
    -o-object-fit: fill;
    object-fit: fill;
    -o-object-position: center;
    object-position: center;
    margin-right: 8px;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.flags-dropdown .dropdown-menu .dropdown-item .inner-text p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
}

.flags-dropdown .dropdown-menu li {
    margin-right: 0;
    display: block;
}