/* 
@BOC
@Scenario #135563-Scenario 1 A ( Updated Requirements )
@author: Maanik Arya
@date : 23-Nov-2022
@use_of_code : Added Style for the prepaid-cod-order-received section
*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Futura-book, sans-serif;
}

p {
    margin: 0;
}

.btn:focus {
    box-shadow: none !important;
}

.main-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #6c757d3b;
    padding: 25px;
}

.main-section {
    padding: 30px 10px;
    text-align: center;
    max-width: 1000px;
    margin: auto;
}

.main-section h4 {
    font-weight: 400;
    font-size : 20px ; 
}

.main-section p {
    margin: 30px 0;
}

.note-msg {
    /* color: #6c757d; */
}

/* Button Section Starts */

.btn-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 50px 0;
}

.btn-dark {
    position: sticky;
    margin: 40px 0;
}

.btn-outline-secondary:hover {
    background-color: #000 !important;
    border: 1px solid #000 !important;
}

.btn-align-center {
    display: block !important;
    margin: 20px auto;
}

.btn-close {
    position: absolute;
    top: 18px;
    right: 14px;
}


.btn-secondary {
    background-color: #fff;
    color: #000;
}

.btn-secondary:focus {
    border-color: none !important;
    box-shadow: none !important;
}

.back-button {
    display: block;
    background-color: #fff;
    border: none;
    padding-bottom: 80px;
    font-size: 18px;
}

.proceed-btn {
    min-width: 100% !important;
}

/* Button Section Ends */


/* ORDER SUMMARY SECTION STARTS  */

.order-history {
    margin-top: 50px;
    padding: 0 50px;
}

.order-history-heading {
    border-bottom: 1px solid #6c757d3b;
}

.main-order-summary {
    display: flex;
    flex-direction: row;
}


/* Order Summary Section Center Starts */

.order-summary-section-center {
    margin: 30px auto;
    width: 100%;
}

.order-summary-section-center .heading-right {
    max-width: 560px;
    text-align: left;
    margin: auto;
    text-decoration: underline;
}

.order-summary-section-center p {
    text-decoration: underline;
}

.order-summary-section-center .order-summary-list-center {
    margin: auto;
    width: 50%;
    max-width: 400px;
    padding: 0;
    list-style: none;
}

.order-summary-section-center .order-summary-list-center li {
    margin-bottom: 20px;
}

.order-summary-section-center .order-summary-list-center .sub-detail-center {
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.order-summary-section-center .order-summary-list-center .sub-detail-center .left {
    width: 50%;
}

.order-summary-section-center .order-summary-list-center .sub-detail-center .center {
    width: 10%;
    padding: 0 10px;
}

.order-summary-section-center .order-summary-list-center .sub-detail-center .right {
    width: 20%;
}

/* Order Summary Section Center Ends */


.order-summary-section {
    margin: 30px auto;
    width: 100%;
}

.order-summary-section .heading-right {
    max-width: 350px;
    text-align: left;
    margin: auto;
    text-decoration: underline;
}

.order-summary-section p {
    text-decoration: underline;
}

.order-summary-section .order-summary-list {
    /* margin: auto; */
    margin-left: 30% ;
    width: 100%;
    max-width: 500px;
    padding: 0;
    list-style: none;
}


.order-summary-section .order-summary-list li {
    margin-bottom: 20px;
}

.order-summary-section .order-summary-list .sub-detail {
    display: flex;
    text-align: left;
}

.order-summary-section .order-summary-list .sub-detail .left {
    width: 50%;
}

.order-summary-section .order-summary-list .sub-detail .center {
    width: 10px;
    padding: 0 10px;
    margin-right: 10px;
}

.order-summary-section .order-summary-list .sub-detail .right {
    width: 50%;
    min-width: 120px;
    padding-left: 20px;
    margin-left: auto;
}

/* ORDER SUMMARY SECTION ENDS  */


/* Pop-up Section Starts */
/**@TaskSupport #138655-Fwd: Checkout Static Pages:- Upated the CSS of modal as per client request.**/
.modal-content {
    max-width: 440px !important;
    width: 100%;
    padding: 30px;
    border: none !important;
    border-radius: 3px;
}

.modal-content h5 {
    text-decoration: underline;
    font-size: 18px;
}

.modal-header {
    border-bottom: none !important;
    position: relative;
}

.modal-footer {
    border-top: none !important;
}

/* Pop-up Section Ends */


.top-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 24px 20px;
    box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.05);
}

.top-section img {
    width: 150px;
    margin-bottom: 20px;
}

.payment-option {
    color: #bbbbbb;
    font-size: 16px;
    margin: 8px 0;
}

.payment-option-link {
    display: flex;
    justify-content: space-between;
    text-decoration: none;
    color: #000;
    margin: 20px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.total-amount {
    margin: 8px 0;
    font-size: 18px;
}

.transaction-id {
    color: #bbbbbb;
    font-size: 12px;
    margin: 8px 0;
}

.id-number {
    display: inline-block;
}

/* .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
} */

.icon-left {
    min-width: 40px;
}

.center {
    width: 80%;
}

.center-heading {
    font-size: 18px;
}

.center-sub-head {
    font-size: 12px;
    color: #bbbbbb;
}

.arrow-right {
    min-width: 30px;
}


/* Payment Gateway Section Starts */

.payment-gateway-container {
    display: flex;
    justify-content: center;
    margin: 50px 300px;
}

.gateway-left-section {
    border: 1px solid #6c757d3b;
    padding: 20px 40px;
    width: 100%;
    max-width: fit-content;
    min-width: 320px;
}

.gateway-right-section {
    border: 1px solid #6c757d3b;
    padding: 20px 40px;
}

.dropdown-toggle {
    min-width: 0;
    margin: 0;
    padding: 0 !important;
}

.logo-img {
    width: 80%;
}

/* Payment Gateway Section Ends */


/* Below is the CSS for the Radio type button  */
.radio-btn {
    padding: 8px 0px;
    margin: 0px auto 20px;
    display: flex;
    width: 100%;
    max-width: 380px;
    cursor: pointer;
}

.radio-btn .input-radio {
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    accent-color: #000;
    margin-right: 6px;
}

.radio-btn .content-area {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.radio-btn .title {
    padding: 0 4px;
}

.radio-btn .price {
    margin-left: auto;
}

/* Custom buttons classes  */
.custom-solid-btn:focus,
.custom-solid-btn:hover,
.custom-solid-btn {
  background-color: #000;
  color: #fff;
  width: 100%;
  max-width: 380px;
  margin: 0 auto 30px;
  box-shadow: none !important;
  display: block;
}

.custom-outlined-btn{
    border: 1px solid black;
    width: 100%;
    max-width: 380px;
    margin: 0 auto 30px;
    display: block;
}

.custom-outlined-btn:hover {
    background-color: rgb(25, 25, 25);
    color: #fff;
    box-shadow: none !important;
}

.custom-filled-btn:hover,
.custom-filled-btn {
    background-color: #000;
    color: #fff;
}

.custom-btn-inline:hover,
.custom-btn-inline{
    display: inline-block;
    width: fit-content;
    padding-left: 85px;
    padding-right: 85px;
    background-color: #000;
    color: #fff;
    margin: 20px auto 0px 0px;
}

/* For collapsable sections */
.order-summary-section .heading-center {
    margin-bottom: 20px;
}
.order-summary-section .heading-center .toggle-btn .text {
    text-decoration: underline;
    text-underline-offset: 5px;
}
.collapsable-div.collapsed {
    overflow: hidden;
    height: 0px;
}

/* Custom accordion starts  */
.custom-accordion{
    max-width: 550px;
    margin: auto;
    margin-bottom: 30px;
}
.custom-accordion-item{
    
}
.custom-accordion-header {
    
}
.accordion-action-btn{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
}

.custom-accordion-content.content-collapsed{
    height: 0;
    overflow: hidden;
}

.accordion-type-list{
    list-style: none;
    /* padding: 0 12px; */
    padding: 0 15px;
    margin: 0;
    max-width: 550px;
    margin: auto;
}
.accordion-type-list .sub-detail{
    display: flex;
    margin-bottom: 15px;
}
.accordion-type-list .sub-detail .left {
    width: 50%;
    text-align: left;
}
.accordion-type-list .sub-detail .center {
    min-width: 10px;
    width: 10px;
}
.accordion-type-list .sub-detail .right {
    width: 50%;
    text-align: left;
}
.accordion-type-list .sub-detail .right .price{
    width: 80%;
    max-width: 250px;
    margin-left: auto;
}
/* Custom accordion ends  */

/* CUSTOM MODAL CHANGES STARTS  */
/**@TaskSupport #138655-Fwd: Checkout Static Pages:- Upated the CSS of modal as per client request.**/
.custom-modal .modal-content {
    padding: 30px;
    margin: auto;
    max-width: 400px !important;
    width: 100% !important;
}
.custom-modal .modal-header{
    position: relative;
}
.custom-modal .modal-header .modal-title{
    text-align: center;
    /** @TaskSupport #138655-Fwd: Checkout Static Pages:- As per the client's requirement, we need to update the font size of the modal heading from 20px to 18px.**/
    /* font-size: 20px; */
    font-size: 18px;
    display: block;
    width: 100%;
    text-decoration: underline;
    text-underline-offset: 8px;
    font-weight: 500;
}
.custom-modal .modal-body {
    max-width: 320px;
    margin: auto;
}
.custom-modal .modal-header .btn-close{
    position: absolute;
    top: 23px;
    right: 19px;
}
.custom-btn-close{
    font-size: 25px;
    padding: 0;
    height: 30px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-preview{
    border: 1px solid rgb(174, 174, 174);
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    padding: 8px 15px;
    position: relative;
    font-weight: 500;
}
.upload-preview .fa{
    color: rgb(174, 174, 174);
    margin-right: 10px;
}
.upload-preview .btn{
    color: #fff;
    background-color: #000;
    padding: 2px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: -10px;
    right: -10px;
}

/* CUSTOM MODAL CHANGES ENDS  */

/* Heading below divider starts */
.heading-divider{
    color: #ccc;
    background-color: #ccc;
}
/* Heading below divider ends */


/* @TaskSupport #138655-Fwd: Checkout Static Pages:- This is the Style Css for the Confirm button in the Thankyou page. */
.btn-white{
    background-color: #fff !important;
    color: #000;
    border: 1px solid black;
    max-width: 380px;
    margin: 0 auto 30px;
    display: block;
}

/* @TaskSupport #138655-Fwd: Checkout Static Pages:- Update the style of the accordion-type list for mobile devices. We need to increase the padding to 16px on both the right and left sides. This adjustment is necessary because the receipt options and the receipt heading are not aligned properly in the design. */
@media ( max-width : 1000px ){
    .order-summary-section .order-summary-list {
        margin: auto;
        /* margin-left: 35% ; */
        width: 100%;
        max-width: 350px;
        padding: 0;
        list-style: none;
    }

    .accordion-type-list{
        list-style: none;
        padding: 0 16px;
        margin: 0;
        max-width: 550px;
        margin: auto;
    }
}