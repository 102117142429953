/* .side_mobile_menu_modal  .ReactModal__Content--after-open {
    right: 0 !important;
} */

.side_mobile_menu_modal > .ReactModal__Overlay {
	opacity: 0;
	transform: translateX(-100px);
	transition: all 300ms ease-in-out;
  }
  
.side_mobile_menu_modal > .ReactModal__Overlay--after-open {
	opacity: 1;
	transform: translateX(0px);
}

.side_mobile_menu_modal > .ReactModal__Overlay--before-close {
	opacity: 0;
	transform: translateX(-100px);
}

@media (max-width: 991px) {
    .side_mobile_menu_modal .menu-box .mobile-menu-img img {
        height: unset;
        width: unset;
    }

    .side_mobile_menu_modal .menu-box {
        height: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 300px;
        background: #fff;
        overflow: hidden;
        overflow-y: auto;
    }

    .indp h3 {
        padding: 15px 20px 15px 40px;
        position: relative;
        font-size: 16px;
    }

    /* .indp h3:before {
        position: absolute;
        content: '';
        background-image: url('/images/icons/left-arrow-black.svg');
        background-size: contain;
        background-repeat: no-repeat;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        height: 15px;
        width: 15px;
    } */

    #mobile_side_sub_menu {
        position: absolute;
        right: 0px !important;
        transition: 1.0s ease-in-out;
        opacity: 1 !important;
        width: 100%;
        top: 140px;
        display: block;
    }

    #mobile_side_sub_menu .accordion p {
        color: #3e4152;
    }

    .indp .accordion-button {
        padding-left: 40px;
    }

    .header-menu .menu-box .accordion-item {
        margin-bottom: 0px;
        border: none;
    }

    .indp .mobile-nav-items {
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    .indp .mobile-nav-items li {
        text-align: left;
        padding: 10px 0px;
        padding-left: 20px;
    }

    .indp .mobile-nav-items li a {
        color: #b6b6b6;
    }

    .other-menu-items {
        text-align: left;
    }

    /* .other-menu-items p {
        padding-left: 20px;
        padding-right: 20px;
    } */

    p.designer-item {
        padding: 10px 0px;
        padding-left: 40px;
        padding-right: 20px;
        text-align: left;
    }

    p.designer-item a {
        text-decoration: none !important;
        color: #000 !important
    }

    #side_mobile_menu_modal {
        background: unset !important;
    }

    #side_mobile_menu_modal .accordion-item {
        margin-bottom: 0px;
    }
    .indp .mobile-nav-items li span {
        color: #3e4152;
    }

    #inner-nav-items li button {
        /* font-weight: 600; */
        font-size: 16px;
        color: #000;
        /* color: #3e4152; */
    }

    .indp h3 {
        text-align: left;
    }

    .side_mobile_menu_modal .other-menu-items {
        padding: 15px 20px 10px 45px;
        border-top: 1px solid #f3f3f3;
    }

    .side_mobile_menu_modal .other-menu-items p a {
        font-size: 16px;
    }

    .mobile-menu-items-main #inner-nav-items li.placeholder-no-after:after {
        content: none;
    }
}

