#back-top {
    background: 0;
    background-size: 100%;
    cursor: pointer;
    position: fixed;
    bottom: 40px;
    right: 12px;
    z-index: 9;
    -webkit-transition: .2s;
    -moz-transition: .2s;
    transition: .2s;
}

#back-top, #back-top span {
    width: 40px;
    height: 40px;
}

#back-top span {
    background-size: cover !important;
    display: block;
    background-position: center;
    background-size: cover;
}


#back-top span:hover {
    transform: scale(1.1);
}

@media (max-width: 768px){
    #back-top {
        height: 50px;
        width: 50px;
        right: 0;
    }
    #back-top span {
        background-repeat: no-repeat;
        background-size: 66%;
    }
}