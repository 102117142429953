@media(max-width: 991px){
    #search-modal {
        height: 100% !important;
    }
}
#search-modal {
    inset: unset !important;
    height: 100%;
    width: 100%;
}

#search-modal .search-box-heading {
    position: relative;
    width: 100%;
}

.search-cross-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    z-index: 9;
    max-width: 10px;
}

.cursor-pointer {
    cursor: pointer;
}

#search-dbl-click {
    color: black;
}