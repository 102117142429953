
.slick-slider .slick-prev {
    left: 1px;
    z-index: 1;
}

.slick-slider .slick-next {
    right: 1px;
    z-index: 1;
}

.slick-slider .slick-next:before, .slick-slider .slick-prev:before {
    opacity: 1;
    color: #000;
    background-color: #fff;
    border-radius: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-slider .slick-next:before, .slick-slider .slick-prev:before {
    opacity: 1;
    color: #000;
    background-color: #fff;
    border-radius: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slick-slider .slick-prev {
    left: 20px;
}

.slick-slider .slick-next {
    right: 20px;
}

.invalid-feedback {
    text-align: left;
}

html {
    scroll-behavior: instant;
  }

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #000; 
    border-color: #000;
}

.btn-outline-primary {
    color: #000;
    border-color: #000;
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #000;
}