.bg {
    position: fixed;
    height: 100%;
    width: 100%;
}

.center-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% ,-50%);
    background: #fff;
    z-index: 9999;
    width: 350px;
    padding: 20px;
    text-align: center;
}

.newsletter-heading {
    position: relative;
}

.newsletter-heading h3 {
    color: #000;
    font-size: 18px;
    text-decoration: underline;
}

.newsletter-heading .close-btn-newsletter {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 20px;
}

form {
    display: block;
    margin: 0;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.ReactModal__Content.ReactModal__Content--after-open {
    /* width: 350px; */
    width: 500px;
    margin: 0 auto;
   text-align: center;
   border: none !important;
   border-radius: unset !important;
}

.close-btn-newsletter{
  height: 20px;
}

.modal-header h3{
   
    color: #000;
   font-size: 18px;
   /* text-decoration: underline; */

}

.modal-header {
    text-align: center;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
}

.modal-header h3 {
    width: 100%;
}

.back-btn-newsletter {
    position: inherit;
}

.verify-otp-container .inputStyle {
    width: 2rem !important;
    height: 2rem;
    margin: 0 1rem;
    font-size: 1.5rem;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
}

.verify-otp-container .btn-row button {
    width: auto;
    margin: 1rem;
    border-radius: 0px;
}

.verify-otp-container .btn-outline-primary:hover {
    color: #fff;
    background-color: #000;
    border-color: #000;
}

@media (max-width: 991px) {
    #authModal {
        /* position: unset !important; */
        inset: unset !important;
        margin: 0px;
        width: 100% !important;
        height: 100% !important;
    }
}


