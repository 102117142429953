.side_cart_modal  .ReactModal__Content--after-open {
    right: 0 !important;
}

.side_cart_modal > .ReactModal__Overlay {
	opacity: 0;
	transform: translateX(100px);
	transition: all 300ms ease-in-out;
  }
  
.side_cart_modal > .ReactModal__Overlay--after-open {
	opacity: 1;
	transform: translateX(0px);
}

.side_cart_modal > .ReactModal__Overlay--before-close {
	opacity: 0;
	transform: translateX(100px);
}

.side_cart_modal .item-box .desc .size-dp, .side_cart_modal .item-box {
    display: flex;
    align-items: center;
}

.side_cart_modal .item-box {
	padding-bottom: 30px;
    border-bottom: 1px solid #e2e2e2;
    position: relative;
}

.side_cart_modal .item-box .desc p {
    color: #949494;
}

.side_cart_modal .item-box .close-btn {
    position: absolute;
    right: 0px;
    top: -18px;
    z-index: 111;
}

.side_cart_modal .item-box .close-btn img {
    height: 13px;
}

.side_cart_modal .close-btn img {
    max-width: 100%;
    width: 17px;
}

.side_cart_modal .item-box .left-img {
    width: 50%;
}

.side_cart_modal .item-box .desc {
    width: 100%;
}

.side_cart_modal .item-box .desc .drpdwns {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.side_cart_modal .item-box .desc .size-dp, .side_cart_modal .item-box {
    display: flex;
    align-items: center;
}

.side_cart_modal .item-box .desc .size-dp {
    display: flex;
    align-items: center;
    width: 100%;
}

.side_cart_modal .item-box .desc .size-dp .dropdown {
    border: 1px solid #e2e2e2;
    border-left: none;
    padding: 0.2px 10px 0.5px 15px;
    width: 100%;
    margin-right: 15px;
}

.side_cart_modal .item-box .desc .size-dp .dropdown button {
    padding: 2.5px 0px;
}

.side_cart_modal .item-box .desc .dropdown .dropdown-menu {
    min-width: 72px;
}

/* .side_cart_modal .subtotal-cart {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    width: 65%;
} */

.side_cart_modal .subtotal-cart h4 {
    font-size: 20px;
}

.cart-text .item-box:first-child {
    margin-top: 15px;
}

#side_cart_modal .modal-header{
    padding: 1rem;
}

#side_cart_modal .modal-body{
    height: 91vh;
   align-items: center;
   vertical-align: middle;
   display: flex;
   justify-content: center;
}   

    .cart-text {
        width: 100%;
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
        padding: 0px 15px 60px 15px;
    }

    .subtotal-cart {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        background: #fff;
        padding: 20px;
        -webkit-box-shadow: 0px -1px 3px #ccc;
        -moz-box-shadow: 0px -1px 3px #ccc;
        box-shadow: 0px -1px 3px #ccc;
    }

    .cart-text .left-img img{
        max-width: 100px;
  }

  .side_cart_modal .item-box .left-img {
    width: 32%;
}
.side_cart_modal .item-box .desc {
    width: 68%;
}
.side_cart_modal .item-box .desc .drpdwns {
    display: flex;
    align-items: center;
    margin-top: 0px;
    flex-wrap: wrap;
}

.side_cart_modal .item-box .desc .size-dp {
    display: flex;
    align-items: center;
    width: 50%;
    margin-top: 0px;
}

.cart-text .desc .drpdwns select{
    padding: 2px 8px;
    border: 1px solid #e2e2e2;
}

.side_cart_modal  {
    z-index: 999;
}


.side_cart_modal .remove-box {
    position: absolute;
    background: white;
    width: 100%;
    z-index: 999;
}
@media (max-width: 991px) {
    /* .btn-side-cart-close {
        margin-left: 5rem;
    } */

    #side_cart_modal {
        width: 100% !important;
        /* height: 100% !important; */
    }
}