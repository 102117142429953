.extra-message{
    /* min-height: 400px;
    height: 80vh; */
    position: relative;
}

.extra-message-btn{
    /* margin: 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    width: 100%;
    max-width: 380px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

/** Added margin bottom CSS for the custom accordion which is use to display two receipts in the receipts details section*/
.custom-accordion-content{
    margin-bottom: 50px;
}

.custom-accordion-content.content-collapsed{
    height: 0;
    overflow: hidden;
}


/* Order Summary Section Center Starts */

.order-summary-section-center {
    margin: 30px auto;
    width: 100%;
}

.order-summary-section-center .heading-right {
    max-width: 560px;
    text-align: left;
    margin: auto;
    text-decoration: underline;
}

.order-summary-section-center p {
    text-decoration: underline;
}

.order-summary-section-center .order-summary-list-center {
    margin: auto;
    width: 50%;
    max-width: 400px;
    padding: 0;
    list-style: none;
}

.order-summary-section-center .order-summary-list-center li {
    margin-bottom: 20px;
}

.order-summary-section-center .order-summary-list-center .sub-detail-center {
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.order-summary-section-center .order-summary-list-center .sub-detail-center .left {
    width: 50%;
}

.order-summary-section-center .order-summary-list-center .sub-detail-center .center {
    width: 10%;
    padding: 0 10px;
}

.order-summary-section-center .order-summary-list-center .sub-detail-center .right {
    width: 20%;
}

/* Order Summary Section Center Ends */


.order-summary-section {
    margin: 30px auto;
    width: 100%;
    max-width: 560px;
}

.order-summary-section .heading-right {
    max-width: 350px;
    text-align: left;
    margin: auto;
    text-decoration: underline;
}

.order-summary-section p {
    text-decoration: underline;
}

.order-summary-section .order-summary-list {
    /* margin: auto; */
    margin-left: 20% ;
    width: 100%;
    max-width: 500px;
    padding: 0;
    list-style: none;
    font-size: 15px;
}



.order-summary-section .order-summary-list li {
    margin-bottom: 20px;
}

.order-summary-section .order-summary-list .sub-detail {
    display: flex;
    text-align: left;
}

.order-summary-section .order-summary-list .sub-detail .left {
    width: 50%;
}

.order-summary-section .order-summary-list .sub-detail .center {
    width: 10px;
    padding: 0 10px;
    margin-right: 10px;
}

.order-summary-section .order-summary-list .sub-detail .right {
    width: 50%;
    min-width: 120px;
    padding-left: 20px;
    margin-left: auto;
}

/* ORDER SUMMARY SECTION ENDS  */


/* Custom accordion starts  */
.custom-accordion{
    max-width: 550px;
    margin: auto;
    margin-bottom: 30px;
}
.custom-accordion-item{
    
}
.custom-accordion-header {
    
}
.accordion-action-btn{
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
}

.custom-accordion-content.content-collapsed{
    height: 0;
    overflow: hidden;
}

.accordion-type-list{
    list-style: none;
    padding: 0 12px;
    margin: 0;
    max-width: 550px;
    margin: auto;
}
.accordion-type-list .sub-detail{
    display: flex;
    margin-bottom: 15px;
}
.accordion-type-list .sub-detail .left {
    width: 50%;
    text-align: left;
}
.accordion-type-list .sub-detail .center {
    min-width: 10px;
    width: 10px;
}
.accordion-type-list .sub-detail .right {
    width: 50%;
    text-align: left;
}
.accordion-type-list .sub-detail .right .price{
    width: 80%;
    max-width: 250px;
    margin-left: auto;
}
/* Custom accordion ends  */

/** Added the CSS for order-summary-section , bank-details-section and receipts-summary-section  */
.extramessages-order-summary-section , .extramessages-bank-details-section{
    max-width: 560px;
    margin: 50px auto auto;
}

.extramessages-receipts-summary-section{
    max-width: 560px;
    margin: 30px auto auto;
}

/* @TaskSupport #138655-Fwd: Checkout Static Pages:- Update the Bank details accordian style as per client requirements.*/
.bank-details-cutom-accordian-style{
	max-width: 335px ;
	margin: auto ;
	text-decoration: underline ;
	font-weight: 400 ;
	text-align: left ;
	font-size: 18px ;
}

/* @TaskSupport #138655-Fwd: Checkout Static Pages:- Update the style of the accordion-type list for mobile devices. We need to increase the padding to 16px on both the right and left sides. This adjustment is necessary because the receipt options and the receipt heading are not aligned properly in the design. */
@media ( max-width : 1000px ){
    .order-summary-section .order-summary-list {
        margin: auto;
        margin-left: 10% ;
        width: 100%;
        max-width: 350px;
        padding: 0;
        list-style: none;
    }

    .accordion-type-list{
        list-style: none;
        padding: 0 16px;
        margin: 0;
        max-width: 550px;
        margin: auto;
    }

    .note-msg{
        padding : 0px 20px;
    }

    *{
        font-size : 15px;
    }

    /* @TaskSupport #138655-Fwd: Checkout Static Pages:- Update the Bank details accordian style as per client requirements.*/
    .bank-details-cutom-accordian-style{
        /* max-width: 335px ; */
        max-width: 295px ;
        margin: auto ;
        text-decoration: underline ;
        font-weight: 400 ;
        text-align: left ;
        font-size: 18px ;
    }

}

