.site-head_menuBar.background--black {
    background-color: #f6f6f6 !important;
    padding: 0px 45px 0px 0 !important;
}

#site-nav__main {
    justify-content: flex-start !important;
    padding-left: 2rem;
}

.darveys-nav-children {
    width: 1170px;
    z-index: 1;
    -webkit-box-shadow: 0px 0px 10px -3px rgb(0 0 0 / 60%);
    -moz-box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.6);
    box-shadow: 0px 0px 10px -3px rgb(0 0 0 / 60%);
}

.col-w {
    width: 20%;
}

.darveys-child-col.col-1 {
    width: 14%;
}

#site-nav__main li .nav-children a {
    text-align: left !important;
    font-weight: 400 !important;
    color: #949494 !important;
}

#site-nav__main li a {
    color: #000 !important;
}

@media (max-width: 1367px) {
    #site-nav__main li a {
        padding: 10px 12px;
    }
}

.serch-header {
    display: flex;
}

.search-head-button img {
    height: 30px;
    max-height: 30px;
}

.darveys-image-menu {
    margin-right: 28px;
}

.darveys-image-sub-menu {
    float: right;
    width: 21%;
    margin-right: 12px;
}

#site-nav__main li .nav-children .darveys-image-sub-menu .inner-item:hover a {
    color: #1a1a1a !important;
    background: transparent !important;
    position: relative;
}